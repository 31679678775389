<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="8" cy="8.5" r="8"  :fill="color || '#015CA1'"/>
  <rect width="12" height="12" transform="translate(2 2.5)"  :fill="color || '#015CA1'"/>
  <rect x="5.29102" y="4.74658" width="5.80859" height="8.57508" fill="white"/>
  <path d="M8.94721 6.97363C8.75199 6.97363 8.59375 7.09848 8.59375 7.25247V11.0302C8.59375 11.1842 8.75199 11.3091 8.94721 11.3091C9.14238 11.3091 9.30068 11.1842 9.30068 11.0302V7.25247C9.30068 7.09848 9.14238 6.97363 8.94721 6.97363Z"  :fill="color || '#015CA1'"/>
  <path d="M7.18159 6.97363C6.98636 6.97363 6.82812 7.09848 6.82812 7.25247V11.0302C6.82812 11.1842 6.98636 11.3091 7.18159 11.3091C7.37676 11.3091 7.53505 11.1842 7.53505 11.0302V7.25247C7.53505 7.09848 7.37676 6.97363 7.18159 6.97363Z"  :fill="color || '#015CA1'"/>
  <path d="M11.744 4.21244H9.85824V3.39494C9.85824 3.17684 9.68521 3 9.47181 3H6.65862C6.44522 3 6.27219 3.17684 6.27219 3.39494V4.21244H4.38643C4.173 4.21244 4 4.38925 4 4.60738C4 4.82551 4.173 5.00232 4.38643 5.00232H4.73437V13.6051C4.73437 13.8231 4.90739 14 5.12079 14H11.0096C11.223 14 11.3961 13.8231 11.3961 13.6051V5.00232H11.744C11.9574 5.00232 12.1304 4.82551 12.1304 4.60738C12.1304 4.38925 11.9574 4.21244 11.744 4.21244ZM7.04505 3.78988H9.08539V4.21244H7.04505V3.78988ZM10.6232 13.2101H5.50722V5.00232H6.65862H9.47181H10.6232V13.2101Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'actionDelete',
  props: ['color'],
}
</script>
