<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.5 8.5C10.5 9.16304 10.2366 9.79893 9.76777 10.2678C9.29893 10.7366 8.66304 11 8 11C7.33696 11 6.70107 10.7366 6.23223 10.2678C5.76339 9.79893 5.5 9.16304 5.5 8.5C5.5 7.83696 5.76339 7.20107 6.23223 6.73223C6.70107 6.26339 7.33696 6 8 6C8.66304 6 9.29893 6.26339 9.76777 6.73223C10.2366 7.20107 10.5 7.83696 10.5 8.5V8.5Z" :fill="color || '#015CA1'"/>
  <path d="M0 8.5C0 8.5 3 3 8 3C13 3 16 8.5 16 8.5C16 8.5 13 14 8 14C3 14 0 8.5 0 8.5ZM8 12C8.92826 12 9.8185 11.6313 10.4749 10.9749C11.1313 10.3185 11.5 9.42826 11.5 8.5C11.5 7.57174 11.1313 6.6815 10.4749 6.02513C9.8185 5.36875 8.92826 5 8 5C7.07174 5 6.1815 5.36875 5.52513 6.02513C4.86875 6.6815 4.5 7.57174 4.5 8.5C4.5 9.42826 4.86875 10.3185 5.52513 10.9749C6.1815 11.6313 7.07174 12 8 12V12Z" :fill="color || '#015CA1'"/>
  </svg>
</template>

<script>
export default {
  name: 'actionDeleteWalet',
  props: ['color'],
}
</script>
