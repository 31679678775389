<template>
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_7689_317594)">
  <path d="M11.1833 2.69993L13.6889 5.20549C13.7944 5.31105 13.7944 5.48327 13.6889 5.58882L7.62222 11.6555L5.04444 11.9416C4.7 11.9805 4.40833 11.6888 4.44722 11.3444L4.73333 8.7666L10.8 2.69993C10.9056 2.59438 11.0778 2.59438 11.1833 2.69993ZM15.6833 2.06382L14.3278 0.708268C13.9056 0.286046 13.2194 0.286046 12.7944 0.708268L11.8111 1.6916C11.7056 1.79716 11.7056 1.96938 11.8111 2.07493L14.3167 4.58049C14.4222 4.68605 14.5944 4.68605 14.7 4.58049L15.6833 3.59716C16.1056 3.17216 16.1056 2.48605 15.6833 2.06382V2.06382ZM10.6667 10.0055V12.8333H1.77778V3.94438H8.16111C8.25 3.94438 8.33333 3.90827 8.39722 3.84716L9.50833 2.73605C9.71944 2.52493 9.56944 2.1666 9.27222 2.1666H1.33333C0.597222 2.1666 0 2.76382 0 3.49993V13.2777C0 14.0138 0.597222 14.611 1.33333 14.611H11.1111C11.8472 14.611 12.4444 14.0138 12.4444 13.2777V8.89438C12.4444 8.59716 12.0861 8.44994 11.875 8.65827L10.7639 9.76938C10.7028 9.83327 10.6667 9.9166 10.6667 10.0055Z"  :fill="color || '#015CA1'"/>
  </g>
  <defs>
  <clipPath id="clip0_7689_317594">
  <rect width="16" height="14.2222" fill="white" transform="translate(0 0.388672)"/>
  </clipPath>
  </defs>
  </svg>
</template>

<script>
export default {
  name: 'actionView',
  props: ['color'],
}
</script>
